<template>

    <app-content :loading="is.loading">
    
        <app-content-head title="Settings" subtitle="Manage your billing settings.">
    
            <app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
            <app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
    
        </app-content-head>
    
        <app-content-body>
    
            <app-content-box>
    
                <app-input-text label="Min. Charge" textPrefix="$" notes="The minimum platform fee deducted from any transaction." placeholder="Enter value..." v-model="model.billing.charge.min" :validation="$v.model.billing.charge.min" />
    
            </app-content-box>
    
        </app-content-body>
    
    </app-content>
    
    </template>
    
    <script>
    
    import mixForm from '@/mixin/form'
    
    export default {
    
        mixins: [mixForm],
    
        data: function() {
    
            return {
                settings: true,
                forceModelLoad: true,
                model: {
                    billing: {
                        charge: {
                            min: 0
                        }
                    }
                }
            }
    
        },
    
        validations: {
            model: {
                billing: {
                    charge: {}
                }
            }
        },
    
        computed: {
    
            defaultSettings: function() {
    
                return []
    
            }
    
        }
    
    }
    
    </script>
    
    <style scoped>
    
    </style>